:root {
  --containerIndent: 60px;
}
@media (max-width: 1279px) {
  :root {
    --containerIndent: 40px;
  }
}
@media (max-width: 1023px) {
  :root {
    --containerIndent: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --containerIndent: 0;
  }
}
:root {
  --deskWidth: 1220px;
  --deskSpace: 80px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1279px) {
  :root {
    --deskSpace: 60px;
  }
}
@media (max-width: 1023px) {
  :root {
    --deskSpace: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 36px;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 12px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 38px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 65px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  .button {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  .button:hover,
  .button:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
/* outfit-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 200;
  src: url('/extras/fonts/outfit-v11-latin-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* outfit-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/outfit-v11-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* outfit-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/outfit-v11-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #64948D;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 300;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  line-height: 1.4;
}
.unit caption {
  display: none;
}
.flag {
  background: #64948D;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.part.pict.tall .cb-image-container {
  width: auto;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 552px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
}
.layout2 #slides {
  height: 100vh !important;
}
@media (max-width: 1023px) {
  #slides {
    height: 460px !important;
  }
}
@media (max-width: 767px) {
  #slides {
    height: 240px !important;
  }
  .layout2 #slides {
    height: 390px !important;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #64948D;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #64948D;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #64948D;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #64948D;
  font-size: 16px;
  font-weight: 300;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  #disp .foot input:hover,
  #disp .foot a:hover,
  #disp .foot input:focus,
  #disp .foot a:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Outfit', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  padding: 5px 0;
  border-bottom: 2px solid #CAD0BB;
  appearance: none;
  color: #64948D;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form input.text:focus,
.unit.form textarea:focus {
  border-color: #64948D !important;
}
.unit.form textarea {
  padding: 10px 0;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 28px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 6px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: left;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  .unit.form .submit {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  .unit.form .submit:hover,
  .unit.form .submit:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
  text-align: left;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  .two-step-verification-container a {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  .two-step-verification-container a:hover,
  .two-step-verification-container a:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 300;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 300;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.60756193vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.homelink {
  float: left;
  height: 42px;
  position: relative;
  margin: 46px 0;
  transition: all 0.4s;
}
.cb-scroll-triggered--active .homelink {
  margin: 24px 0;
}
@media (max-width: 767px) {
  .homelink {
    height: 38px;
    margin: 26px 0;
  }
  .cb-scroll-triggered--active .homelink {
    margin: 18px 0;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: 60px;
  margin-bottom: var(--spaceTotal);
}
.section--multimood #head {
  position: absolute;
  left: calc((100% - var(--deskWidth)) / 2);
  bottom: 148px;
  z-index: 2;
  margin: 0;
  width: calc((var(--deskWidth) / 2) - 50px);
}
@media (max-width: 1380px) {
  .section--multimood #head {
    left: var(--deskSpace);
    width: calc((var(--deskMaxWidth) / 2) - 50px);
  }
}
@media (max-width: 1023px) {
  .section--multimood #head {
    width: calc((var(--deskMaxWidth) / 2) - 20px);
  }
}
@media (max-width: 767px) {
  #head {
    margin-top: 32px;
  }
  .section--multimood #head {
    width: var(--deskMaxWidth);
    bottom: var(--deskSpace);
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #000;
  overflow: hidden;
  hyphens: none;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  transition: all 0.4s 0.2s;
}
body:not(.cb-toggle-target-active) .section--header.cb-scroll-triggered--active {
  background-color: #64948D;
  transition: all 0.4s;
}
.section--multimood {
  color: #fff;
}
.cbdModule--stoerer {
  width: auto;
  transform: rotate(12deg);
  position: absolute;
  right: 33%;
  bottom: 120px;
  z-index: 1000;
}
.cbdModule--stoerer .stoerer {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  width: 258px;
  height: 258px;
  background-color: #CAD0BB;
  color: #64948D;
  border-radius: 10000px;
  font-size: 32px;
  line-height: 1.3125;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.4s;
}
.cbdModule--stoerer .stoerer:hover,
.cbdModule--stoerer .stoerer:focus {
  transform: scale(1.05);
}
@media (max-width: 1600px) {
  .cbdModule--stoerer {
    right: var(--deskSpace);
  }
}
@media (max-width: 1023px) {
  .cbdModule--stoerer {
    bottom: 100px;
  }
  .cbdModule--stoerer .stoerer {
    width: 160px;
    height: 160px;
    padding: 10px;
    font-size: 20px;
    line-height: 1.2;
  }
}
@media (max-width: 767px) {
  .cbdModule--stoerer {
    top: 100px;
    bottom: unset;
  }
}
.section--footer {
  background-color: #64948D;
  margin-top: var(--spaceTotal);
}
.content {
  float: left;
  width: 100%;
}
.section--header .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subcontent {
  float: left;
  width: 100%;
}
.section--one .subcontent {
  box-sizing: border-box;
}
.section--one .subcontent.subcontent--2 {
  position: relative;
  z-index: 3;
  background-color: #fff;
  margin-top: -148px;
  padding: var(--containerIndent) var(--containerIndent) 0;
}
#view.area1--empty .section--one .subcontent.subcontent--2 {
  display: none;
}
.layout2 .section--one .subcontent.subcontent--1 {
  padding-left: var(--containerIndent);
  padding-right: var(--containerIndent);
}
@media (max-width: 1023px) {
  .section--one .subcontent.subcontent--2 {
    margin-top: -120px;
  }
}
@media (max-width: 767px) {
  .section--one .subcontent.subcontent--2 {
    margin-top: 0;
  }
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 46px 0;
}
@media (max-width: 767px) {
  .footcontent {
    flex-direction: column;
    margin: 30px 0;
  }
}
.footpart {
  float: left;
}
@media (max-width: 767px) {
  .footpart {
    width: 100%;
  }
}
.container--address {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .container--address {
    flex-direction: column;
  }
}
.address__title {
  float: left;
  width: 100%;
  font-size: 26px;
  line-height: 1.23076923;
  font-weight: 300;
  color: #CAD0BB;
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .address__title {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 14px;
  }
}
.address__content {
  float: left;
  display: flex;
}
@media (max-width: 767px) {
  .address__content {
    flex-direction: column;
  }
}
.vcard {
  float: left;
  font-size: 16px;
  line-height: 1.25;
  color: #fff;
}
.vcard--one {
  margin-right: 40px;
}
.vcard .adr {
  color: #CAD0BB;
}
.vcard a {
  color: #fff;
  text-decoration: none;
}
.vcard a:hover,
.vcard a:focus {
  color: #fff;
  text-decoration: underline 1px;
}
@media (max-width: 767px) {
  .vcard {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
.address__legal {
  float: left;
}
#legalNavi {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#legalNavi .meta {
  font-size: 16px;
  line-height: 1.25;
  margin-top: 20px;
  text-decoration: none;
  color: #fff;
}
#legalNavi .meta:first-child {
  margin-top: 0;
}
#legalNavi .meta:hover,
#legalNavi .meta:focus {
  color: #fff;
  text-decoration: underline 1px;
}
@media (max-width: 1023px) {
  #legalNavi .meta {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
@media (max-width: 767px) {
  #legalNavi .meta {
    margin-top: 12px;
  }
}
.batch {
  position: absolute;
  right: 70px;
  top: -106px;
  z-index: 2;
  width: 134px;
  transform: rotate(15deg);
  transition: all 0.4s;
  display: none;
}
.layout1.batchVisible .batch {
  display: block;
}
.batch:hover {
  transform: rotate(0);
}
@media (max-width: 1023px) {
  .batch {
    right: 20px;
    top: -80px;
    width: 100px;
  }
}
@media (max-width: 767px) {
  .batch {
    right: -20px;
  }
}
a {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 4px;
}
a:hover,
a:focus {
  color: #64948D;
}
h1 {
  font-size: 80px;
  line-height: 1.125;
  font-weight: 200;
  color: #64948D;
}
.layout2 h1 {
  color: #fff;
}
h2 {
  font-size: 32px;
  line-height: 1.3125;
  font-weight: 300;
  color: #64948D;
}
.pale {
  font-size: 16px;
  line-height: 1.75;
}
.area--one.area3 {
  margin-top: var(--spaceTotal);
}
.area--one .unit .pict {
  width: calc(100% + (var(--containerIndent) * 2));
  margin-left: calc(var(--containerIndent) * -1);
}
@media (max-width: 767px) {
  .area--one .unit .pict {
    width: 100%;
    margin-left: 0;
  }
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.area--two .unitOne--1-2WithIndent {
  box-sizing: border-box;
}
.area--two .unitTwo {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  position: relative;
}
.area--two .unitTwo .unit__background {
  position: relative;
  float: left;
  width: 100%;
}
.area--two .unitTwo .unit__content {
  box-sizing: border-box;
}
.area--two .unitTwo .unit__body {
  margin-top: calc(var(--spacePart) * -1);
  margin-bottom: calc(var(--spacePart) * -1);
}
.area--two .unitTwo.unit--isAnchor {
  color: #000;
}
.area--two .unitTwo.unit--isAnchor .unit__background {
  overflow: hidden;
}
.area--two .unitTwo.unit--isAnchor .unit__background .unit__backgroundImage {
  transition: all 0.4s;
}
.area--two .unitTwo.unit--isAnchor .unit__body {
  padding-bottom: 80px;
  position: relative;
}
.area--two .unitTwo.unit--isAnchor .unit__body:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: var(--spacePart);
  width: 160px;
  height: 16px;
  background-size: 1000px 16px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
.area--two .unitTwo.unit--isAnchor:hover .unit__background .unit__backgroundImage {
  transform: scale(1.05);
}
.area--two .unitTwo.unit--isAnchor:hover .unit__body:after {
  width: 100%;
}
@media (max-width: 767px) {
  .area--two .unitTwo .unit__background {
    margin-bottom: calc(var(--spacePart) * 2);
  }
  .area--two .unitTwo.unit--isAnchor .unit__body {
    padding-bottom: 40px;
  }
}
.area--two .unitThree {
  width: 100vw !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.area--two .unitThree:last-child {
  margin-bottom: calc(var(--spaceTotal) * -1);
}
.area--two .unitThree .part {
  margin-top: 0;
  margin-bottom: 0;
}
div.link .open {
  display: inline-block;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
div.link .open:hover,
div.link .open:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  div.link .open {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  div.link .open:hover,
  div.link .open:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
div.load .load {
  display: inline-block;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
div.load .load:hover,
div.load .load:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  div.load .load {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  div.load .load:hover,
  div.load .load:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
.part.cb-googlemaps,
.part.cb-googlemaps .cb-googlemapscontainer,
.part.cb-googlemaps .cb-undraggable {
  height: 740px !important;
}
@media (max-width: 1023px) {
  .part.cb-googlemaps,
  .part.cb-googlemaps .cb-googlemapscontainer,
  .part.cb-googlemaps .cb-undraggable {
    height: 400px !important;
  }
}
.togglenavigation {
  float: left;
  position: relative;
  width: 32px;
  height: 22px;
  cursor: pointer;
}
.tline {
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .tline {
    height: 4px;
  }
}
.tline--1 {
  top: 0;
  transition: top 0.2s 0.1s, transform 0.2s;
}
body.cb-toggle-target-active .tline--1 {
  top: calc(50% - 3px);
  transform: rotate(-45deg);
  transition: top 0.2s, transform 0.2s 0.1s;
}
@media (max-width: 767px) {
  body.cb-toggle-target-active .tline--1 {
    top: calc(50% - 2px);
  }
}
.tline--2 {
  bottom: 0;
  transition: bottom 0.2s 0.1s, transform 0.2s;
}
body.cb-toggle-target-active .tline--2 {
  bottom: calc(50% - 3px);
  transform: rotate(45deg);
  transition: bottom 0.2s, transform 0.2s 0.1s;
}
@media (max-width: 767px) {
  body.cb-toggle-target-active .tline--2 {
    bottom: calc(50% - 2px);
  }
}
.navigation {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #64948D;
  overflow: hidden;
  overflow-y: auto;
  transform: translateY(-100%);
  transition: all 0.6s;
}
body.cb-toggle-target-active .navigation {
  transform: translateY(0);
}
.navigation:before {
  content: '';
  position: absolute;
  left: 0;
  transform: translateX(-50%);
  top: calc(100% / 1000 * 193);
  width: 203px;
  height: 203px;
  border-radius: 10000px;
  background-color: #CAD0BB;
}
@media (max-width: 1460px) {
  .navigation:before {
    display: none;
  }
}
.navigation__cover {
  float: left;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 240px 0 46px;
}
@media (max-width: 767px) {
  .navigation__cover {
    padding: 160px 0 30px;
  }
}
.navigation__desk {
  float: left;
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.navigation__content {
  float: left;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navigation__section {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 0.2s;
}
body.cb-toggle-target-active .navigation__section {
  opacity: 1;
  transform: translateY(0);
}
body.cb-toggle-target-active .navigation__section--1 {
  transition: all 0.6s 0.3s;
}
.navigation__section--2 {
  margin-top: 120px;
}
body.cb-toggle-target-active .navigation__section--2 {
  transition: all 0.6s 0.4s;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
div.sub1 > .item {
  margin-top: 20px;
}
div.sub1 > .item.init {
  margin-top: 0;
}
div.sub1 > .item > .menu {
  font-size: 80px;
  line-height: 1.125;
  font-weight: 200;
  text-decoration: none;
  color: #CAD0BB;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus,
div.sub1 > .item > .menu.path {
  color: #fff;
}
@media (max-width: 767px) {
  div.sub1 > .item > .menu {
    font-size: 40px;
    line-height: 1.1;
  }
}
.realestate-item-group {
  float: left;
  width: 100%;
}
.realestate {
  float: left;
  width: 100%;
  color: #000;
  font-size: 20;
  line-height: 1.4;
}
.realestate__section {
  float: left;
  width: 100%;
  margin-top: 50px;
}
.realestate__section:first-child {
  margin-top: 0;
}
.realestate__section--table {
  overflow-x: auto;
}
.realestate__section--actions--top {
  display: none;
}
.realestate__section--svg {
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/iso-background-2024-12-16.jpg);
}
@media only screen and (max-width: 767px) {
  .realestate__section--svg {
    display: none !important;
  }
}
.realestate svg {
  float: left;
  width: 100%;
  overflow: visible;
}
.appartment {
  cursor: pointer;
}
.appartment * {
  fill: rgba(202, 208, 187, 0.65);
  stroke: #fff;
  stroke-width: 0.25;
  transition: all 0.4s;
}
.appartment.is-hovered *,
.appartment.is-active * {
  fill: rgba(100, 148, 141, 0.6);
}
.filter-section {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: 50px;
}
.filter-section:first-child {
  margin-top: 0;
}
.filter-section:empty {
  display: none;
}
.filter-section--selectors {
  position: relative;
  z-index: 2;
}
.filter {
  float: left;
  width: calc(25% - 20px);
  max-width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
.filter.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .filter {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
  }
  .filter:first-child {
    margin-top: 0;
  }
}
.realestate .o-label {
  display: block;
  padding-bottom: 10px;
  font-weight: 300;
}
.realestate .o-form-group {
  float: left;
  width: 100%;
}
.realestate .o-form-input {
  float: left;
  width: 100%;
}
.realestate .o-dropdown {
  float: left;
  width: 100%;
  position: relative;
  background-color: #F0F0F0;
}
.realestate .o-dropdown__selected {
  float: left;
  width: 100%;
  position: relative;
}
.realestate .o-dropdown__link {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  background-color: #64948D;
  background-image: url(/images/realestate-arrow-down.svg);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 2;
}
.realestate .o-dropdown__link:hover {
  cursor: pointer;
}
.realestate .o-dropdown.is-open .o-dropdown__link {
  background-image: url(/images/realestate-arrow-up.svg);
}
.realestate .o-dropdown__results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #F0F0F0;
  z-index: 3;
  display: none;
  border-top: 1px solid #fff;
}
.realestate .o-dropdown.is-open .o-dropdown__results {
  display: block;
}
.realestate .o-dropdown__item {
  padding: 4px 10px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.realestate .o-dropdown__item:hover {
  cursor: pointer;
  background-color: #d7d7d7;
}
.realestate .o-dropdown__selected .o-dropdown__item {
  padding-right: 60px;
}
.realestate .o-dropdown__item.is-active {
  color: #64948D;
}
.o-slider {
  float: left;
  width: 100%;
}
.o-slider__bar {
  float: left;
  width: 100%;
  margin: 10px 0;
  position: relative;
  height: 1px;
  background-color: #000;
}
.o-slider-handler {
  position: absolute;
  top: -10px;
  width: 21px;
  height: 21px;
  background-color: #64948D;
  border-radius: 21px;
}
.o-slider-handler:hover {
  cursor: pointer;
}
.o-slider-handler--min {
  left: 0;
}
.o-slider-handler--max {
  left: 100%;
}
.o-slider__values {
  float: left;
  width: 100%;
  margin-top: 5px;
}
.o-slider-value--min {
  float: left;
}
.o-slider-value--max {
  float: right;
}
.realestate-button {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F0F0F0;
  font-weight: 300;
  min-height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  cursor: pointer;
  margin: 0 20px;
}
.realestate-button:first-child {
  margin-left: 0;
}
.realestate-button:last-child {
  margin-right: 0;
}
.realestate-button:hover {
  color: #fff;
  background-color: #64948D;
}
.realestate-button.is-disabled {
  opacity: 0.6;
  pointer-events: none;
  color: #000;
  background-color: #F0F0F0;
}
@media only screen and (max-width: 767px) {
  .realestate-button {
    width: 100%;
  }
}
.table--realestate {
  float: left;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
.table--realestate tr.table-appartment {
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  background-color: #F0F0F0;
  cursor: pointer;
  display: none;
}
.table--realestate tr.table-appartment a:hover,
.table--realestate tr.table-appartment a:focus {
  color: inherit;
}
.table--realestate tr.table-appartment:last-child {
  border-bottom: none;
}
.table--realestate tr.table-appartment.is-visible {
  display: table-row;
}
.table--realestate tr.table-appartment.is-hovered,
.table--realestate tr.table-appartment.is-active {
  background-color: #64948D;
  color: #fff;
  cursor: pointer;
}
.table--realestate tr.table-appartment.is-hovered .icon-contact,
.table--realestate tr.table-appartment.is-active .icon-contact {
  background-color: #fff;
}
.table--realestate tr.table-appartment.is-disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: #F0F0F0 !important;
  color: #000 !important;
}
.table--realestate th {
  font-weight: 300;
  padding: 0 10px;
  vertical-align: middle;
  white-space: nowrap;
}
.table--realestate td {
  height: 50px;
  padding: 0 10px;
  vertical-align: middle;
  position: relative;
}
.table--realestate td .icon-contact {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 25px;
  height: 25px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-contact.svg);
  background-color: #000;
}
.table--realestate .heading--mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .table--realestate thead {
    display: none;
  }
  .table--realestate tr.table-appartment {
    padding: 5px 0;
    color: #000 !important;
    background-color: #F0F0F0 !important;
    border-width: 5px 0;
  }
  .table--realestate tr.table-appartment.is-visible {
    display: block !important;
  }
  .table--realestate tr.table-appartment.is-active {
    background-color: #64948D !important;
  }
  .table--realestate td {
    display: block;
    text-align: right !important;
    height: auto;
    padding: 5px 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .table--realestate .heading--mobile {
    display: inline;
    width: 50%;
    text-align: left !important;
  }
}
.realestate-information {
  float: left;
  width: 100%;
}
.information-text {
  float: left;
  width: 100%;
}
.realestate--manage {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  font-size: 14px;
  line-height: 1.2;
  font-family: 'Outfit', sans-serif;
  color: #333;
}
.realestate--manage input,
.realestate--manage textarea,
.realestate--manage select {
  display: block;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 3px;
  background-color: #fff;
}
.realestate--manage * {
  font-size: 14px;
  line-height: 1.2;
  font-family: helvetica;
  color: #333;
}
.realestate--manage a:hover {
  color: #555;
}
.realestate--manage .hidden {
  display: none !important;
}
.manage-section {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.realestate-configuration .realestate-form-group--file {
  width: 100%;
  margin-top: 10px;
}
.realestate-configuration .realestate-form-group--file .realestate-form-input {
  margin-left: 0;
}
.realestate-heading {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 300;
}
.realestate-form-group {
  float: left;
  margin-right: 40px;
}
.realestate-form-group:last-child {
  margin-left: 0;
}
.realestate-label,
.realestate-form-label {
  float: left;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.realestate-form-input {
  float: left;
  margin-left: 10px;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.manage-button {
  float: left;
  display: block;
  background-color: #333;
  color: #fff;
  line-height: 32px;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: 300;
}
.manage-button:hover {
  background-color: #444;
  cursor: pointer;
}
.manage-button--delete,
.manage-button--download,
.manage-button--upload,
.manage-button--save,
.manage-button--edit,
.manage-button--abort {
  width: 32px;
  height: 32px;
  padding: 0;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 50% 50%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
.manage-button--delete {
  background-image: url(/icons/realestate/realestate-trash-white.svg);
}
.manage-button--download {
  background-image: url(/icons/realestate/realestate-download-white.svg);
}
.manage-button--upload {
  background-image: url(/icons/realestate/realestate-upload-white.svg);
}
.manage-button--save {
  background-image: url(/icons/realestate/realestate-save-white.svg);
}
.manage-button--edit {
  background-image: url(/icons/realestate/realestate-edit-white.svg);
}
.manage-button--abort {
  background-image: url(/icons/realestate/realestate-abort-white.svg);
}
.manage-section--report table {
  width: 100%;
  margin-bottom: 40px;
  border-collapse: collapse;
}
.manage-section--report table tr {
  min-height: 36px;
}
.manage-section--report table tr.odd {
  background: #eee;
}
.manage-section--report table th,
.manage-section--report table td {
  text-align: left;
  vertical-align: middle;
  padding: 5px 10px;
  white-space: nowrap;
}
.manage-section--report table th .button-width,
.manage-section--report table td .button-width {
  float: left;
  min-width: 156px;
}
.manage-section--report table th .manage-button,
.manage-section--report table td .manage-button {
  margin: 5px;
}
.manage-section--report table th .manage-button:first-child,
.manage-section--report table td .manage-button:first-child {
  margin-left: 0;
}
.manage-section--report table th .manage-button:last-child,
.manage-section--report table td .manage-button:last-child {
  margin-right: 0;
}
.manage-section--report table th {
  padding: 20px 10px;
  background: #333;
  color: #fff;
}
.manage-section--report table th a {
  color: #fff;
  text-decoration: none;
}
.manage-section--report table th a:hover,
.manage-section--report table th a:focus {
  color: #ccc;
}
.manage-section--report table .report-sort--desc,
.manage-section--report table .report-sort--asc {
  padding-right: 20px;
  background-image: url(/icons/realestate/realestate-sort-white.svg);
  background-size: 10px 10px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.realestate-configuration {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  font-family: helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.2;
}
.realestate-configuration .realestate-configuration,
.realestate-configuration.realestate-configuration--data {
  padding: 0;
}
.realestate-configuration h1 {
  float: left;
  width: 100%;
  font-weight: 300;
}
.realestate-configuration h3 {
  float: left;
  width: 100%;
  margin-top: 50px;
}
.realestate-tab-container {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.realestate-content {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.realestate-configuration .realestate--manage {
  box-sizing: border-box;
  padding: 0 40px;
}
.realestate-configuration .realestate--manage:nth-child(2n-1) {
  background-color: #e3f4fd;
}
.realestate-configuration--global {
  padding: 0;
  border-top: 1px solid #ccc;
}
.re-config-content {
  float: left;
  width: 100%;
}
.re-config-content .realestate-form-group {
  float: left;
  width: auto;
}
.re-config-content input {
  float: left;
}
.realestate-definition {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.realestate-definition:nth-child(even) {
  background: #eee;
}
.realestate-definition .realestate-definition-actions {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.realestate-definition h4 {
  font-size: 16px;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.realestate-definition a {
  text-decoration: underline;
  margin-right: 10px;
}
.definition label {
  width: 100%;
  display: block;
  margin-top: 20px;
}
.definition textarea {
  width: 50%;
  min-height: 200px;
  margin-bottom: 20px;
}
.realestate-definition-add {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .layout2 .section--one .content {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .section--one .subcontent.subcontent--1 {
    order: 2;
  }
  .section--one .subcontent.subcontent--2 {
    order: 1;
  }
}
@media (max-width: 1023px) {
  .wrapper {
    font-size: 16px;
    line-height: 1.5;
  }
  h1 {
    font-size: 40px;
    line-height: 1.1;
  }
  h2 {
    font-size: 26px;
    line-height: 1.23076923;
  }
  .pale {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
/*# sourceMappingURL=./screen-small.css.map */